<template>
  <svg viewBox="0 0 588 479" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Banner</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="right-shape-linearGradient-1">
        <stop :stop-color="colorA" offset="0%"></stop>
        <stop :stop-color="colorB" offset="100%"></stop>
      </linearGradient>
      <path d="M510.5,447.567522 C578.241002,413.396308 593.151758,344.362037 665.725888,319.448381 C738.300018,294.534725 773.64262,338.567259 818.833548,338.567259 C899.92857,338.567259 912,246.002687 912,141.926696 C912,-80.0512889 732.242327,-260 510.5,-260 C288.757673,-260 109,-80.0512889 109,141.926696 C109,363.90468 294.151899,556.701976 510.5,447.567522 Z" id="right-shape-path-2"></path>
    </defs>
    <g id="right-shape-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.15">
      <g id="right-shape-Desktop-/-Trial-/-1.0---Login" transform="translate(-852.000000, 0.000000)">
        <g id="right-shape-Banner" transform="translate(743.000000, 0.000000)">
          <mask id="right-shape-mask-3" fill="white">
            <use xlink:href="#right-shape-path-2"></use>
          </mask>
          <use id="right-shape-Oval" fill="url(#right-shape-linearGradient-1)" xlink:href="#right-shape-path-2"></use>
          <g opacity="0.3" style="mix-blend-mode: overlay;" mask="url(#right-shape-mask-3)" id="right-shape-Shape">
            <g transform="translate(-887.000000, -797.000000)">
              <path d="M1002.2,1266.1 L999.6,1258.2 C999.2,1256.9 999.9,1255.5 1001.2,1255 L1009.1,1252.4 C1010.4,1252 1011.8,1252.7 1012.3,1254 L1014.9,1261.9 C1015.3,1263.2 1014.6,1264.6 1013.3,1265.1 L1005.4,1267.7 C1004,1268.2 1002.6,1267.4 1002.2,1266.1 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
              <path d="M1531,1190.8 L1534.7,1181 C1536.3,1176.8 1541,1174.7 1545.2,1176.3 L1555,1180 C1559.2,1181.6 1561.3,1186.3 1559.7,1190.5 L1556,1200.3 C1554.4,1204.5 1549.7,1206.6 1545.5,1205 L1535.7,1201.3 C1531.5,1199.7 1529.4,1195 1531,1190.8 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1530.1,977.6 L1537.1,969.8 C1540.1,966.5 1545.2,966.2 1548.5,969.1 L1556.3,976.1 C1559.6,979.1 1559.9,984.2 1557,987.5 L1550,995.3 C1547,998.6 1541.9,998.9 1538.6,996 L1530.8,989 C1527.4,986 1527.1,980.9 1530.1,977.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1542.6,928.4 L1543.6,923.6 C1543.7,923.2 1544.1,922.9 1544.5,923 L1549.3,924 C1549.7,924.1 1550,924.5 1549.9,924.9 L1548.9,929.7 C1548.8,930.1 1548.4,930.4 1548,930.3 L1543.2,929.3 C1542.8,929.3 1542.5,928.9 1542.6,928.4 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1575.6,1055.4 L1579.2,1052 C1579.5,1051.7 1580,1051.7 1580.3,1052 L1583.7,1055.6 C1584,1055.9 1584,1056.4 1583.7,1056.7 L1580.1,1060.1 C1579.8,1060.4 1579.3,1060.4 1579,1060.1 L1575.6,1056.5 C1575.2,1056.2 1575.3,1055.7 1575.6,1055.4 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1468.4,1020.7 L1466.5,1016.2 C1466.3,1015.8 1466.5,1015.3 1466.9,1015.1 L1471.4,1013.2 C1471.8,1013 1472.3,1013.2 1472.5,1013.6 L1474.4,1018.1 C1474.6,1018.5 1474.4,1019 1474,1019.2 L1469.5,1021.1 C1469,1021.3 1468.5,1021.1 1468.4,1020.7 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1539.3,1122.2 L1535.4,1114.8 C1534.7,1113.6 1535.2,1112.1 1536.4,1111.4 L1543.8,1107.5 C1545,1106.8 1546.5,1107.3 1547.2,1108.5 L1551.1,1115.9 C1551.8,1117.1 1551.3,1118.6 1550.1,1119.3 L1542.7,1123.2 C1541.4,1123.9 1539.9,1123.4 1539.3,1122.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1475.6,1144.7 L1472,1137.1 C1471.4,1135.8 1471.9,1134.4 1473.2,1133.8 L1480.8,1130.2 C1482.1,1129.6 1483.5,1130.1 1484.1,1131.4 L1487.7,1139 C1488.3,1140.3 1487.8,1141.7 1486.5,1142.3 L1478.9,1145.9 C1477.7,1146.5 1476.2,1146 1475.6,1144.7 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1498.3,1050.2 L1506.6,1050.8 C1508,1050.9 1509,1052.1 1508.9,1053.5 L1508.3,1061.8 C1508.2,1063.2 1507,1064.2 1505.6,1064.1 L1497.3,1063.5 C1495.9,1063.4 1494.9,1062.2 1495,1060.8 L1495.6,1052.5 C1495.7,1051.1 1496.9,1050.1 1498.3,1050.2 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1474.7,921.6 L1472.1,913.7 C1471.7,912.4 1472.4,911 1473.7,910.5 L1481.6,907.9 C1482.9,907.5 1484.3,908.2 1484.8,909.5 L1487.4,917.4 C1487.8,918.7 1487.1,920.1 1485.8,920.6 L1477.9,923.2 C1476.6,923.7 1475.2,923 1474.7,921.6 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1391.6,865.9 L1395.3,856.1 C1396.9,851.9 1401.6,849.8 1405.8,851.4 L1415.6,855.1 C1419.8,856.7 1421.9,861.4 1420.3,865.6 L1416.6,875.4 C1415,879.6 1410.3,881.7 1406.1,880.1 L1396.3,876.4 C1392.1,874.8 1390,870.1 1391.6,865.9 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1515.1,867.3 L1534.4,859.5 C1535.9,858.9 1537.6,859.6 1538.2,861.1 L1546,880.4 C1546.6,881.9 1545.9,883.6 1544.4,884.2 L1525.1,892 C1523.6,892.6 1521.9,891.9 1521.3,890.4 L1513.5,871.1 C1512.9,869.6 1513.6,867.9 1515.1,867.3 Z" stroke="#121E48" stroke-width="3" fill="none"></path>
              <path d="M1459.3,859.4 L1461.1,854.8 C1461.3,854.4 1461.7,854.2 1462.1,854.3 L1466.7,856.1 C1467.1,856.3 1467.3,856.7 1467.2,857.1 L1465.4,861.7 C1465.2,862.1 1464.8,862.3 1464.4,862.2 L1459.8,860.4 C1459.3,860.3 1459.1,859.8 1459.3,859.4 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1494.7,809.8 L1499.3,807.9 C1499.7,807.7 1500.2,807.9 1500.3,808.3 L1502.2,812.9 C1502.4,813.3 1502.2,813.8 1501.8,813.9 L1497.2,815.8 C1496.8,816 1496.3,815.8 1496.2,815.4 L1494.3,810.8 C1494,810.5 1494.2,810 1494.7,809.8 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1399.8,797.3 L1395.9,789.9 C1395.2,788.7 1395.7,787.2 1396.9,786.5 L1404.3,782.6 C1405.5,781.9 1407,782.4 1407.7,783.6 L1411.6,791 C1412.3,792.2 1411.8,793.7 1410.6,794.4 L1403.2,798.3 C1402,799 1400.5,798.5 1399.8,797.3 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1336.2,819.8 L1332.6,812.2 C1332,810.9 1332.5,809.5 1333.8,808.9 L1341.4,805.3 C1342.7,804.7 1344.1,805.2 1344.7,806.5 L1348.3,814.1 C1348.9,815.4 1348.4,816.8 1347.1,817.4 L1339.5,821 C1338.3,821.6 1336.8,821.1 1336.2,819.8 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1565.1,837.2 L1563.4,829 C1563.1,827.6 1564,826.3 1565.3,826 L1573.5,824.3 C1574.9,824 1576.2,824.9 1576.5,826.2 L1578.2,834.4 C1578.5,835.8 1577.6,837.1 1576.3,837.4 L1568.1,839.1 C1566.7,839.4 1565.3,838.5 1565.1,837.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1557.6,1283.3 L1559.7,1273 C1560.6,1268.6 1564.9,1265.8 1569.3,1266.7 L1579.6,1268.8 C1584,1269.7 1586.8,1274 1585.9,1278.4 L1583.8,1288.7 C1582.9,1293.1 1578.6,1295.9 1574.2,1295 L1563.9,1292.9 C1559.5,1292 1556.7,1287.7 1557.6,1283.3 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1464.1,1300.9 L1460,1291.3 C1458.2,1287.2 1460.1,1282.4 1464.3,1280.7 L1473.9,1276.6 C1478,1274.8 1482.8,1276.7 1484.5,1280.9 L1488.6,1290.5 C1490.4,1294.6 1488.5,1299.4 1484.3,1301.1 L1474.7,1305.2 C1470.6,1306.9 1465.9,1305 1464.1,1300.9 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1378.4,1238.7 L1379.4,1233.9 C1379.5,1233.5 1379.9,1233.2 1380.3,1233.3 L1385.1,1234.3 C1385.5,1234.4 1385.8,1234.8 1385.7,1235.2 L1384.7,1240 C1384.6,1240.4 1384.2,1240.7 1383.8,1240.6 L1379,1239.6 C1378.6,1239.6 1378.3,1239.2 1378.4,1238.7 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1310.5,1231.9 L1307.9,1224 C1307.5,1222.7 1308.2,1221.3 1309.5,1220.8 L1317.4,1218.2 C1318.7,1217.8 1320.1,1218.5 1320.6,1219.8 L1323.2,1227.7 C1323.6,1229 1322.9,1230.4 1321.6,1230.9 L1313.7,1233.5 C1312.4,1234 1311,1233.2 1310.5,1231.9 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1426.9,1209.1 L1432,1195.9 C1433.2,1192.7 1436.9,1191.1 1440.1,1192.3 L1453.3,1197.4 C1456.5,1198.6 1458.1,1202.3 1456.9,1205.5 L1451.8,1218.7 C1450.6,1221.9 1446.9,1223.5 1443.7,1222.3 L1430.5,1217.2 C1427.3,1215.9 1425.7,1212.3 1426.9,1209.1 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M1418.2,958.4 L1420.3,948.1 C1421.2,943.7 1425.5,940.9 1429.9,941.8 L1440.2,943.9 C1444.6,944.8 1447.4,949.1 1446.5,953.5 L1444.4,963.8 C1443.5,968.2 1439.2,971 1434.8,970.1 L1424.5,968 C1420.1,967 1417.3,962.8 1418.2,958.4 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1397.7,1044.6 L1405.4,1037.5 C1408.7,1034.5 1413.8,1034.7 1416.8,1037.9 L1423.9,1045.6 C1426.9,1048.9 1426.7,1054 1423.5,1057 L1415.8,1064.1 C1412.5,1067.1 1407.4,1066.9 1404.4,1063.7 L1397.3,1056 C1394.2,1052.8 1394.4,1047.7 1397.7,1044.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1324.7,976 L1320.6,966.4 C1318.8,962.3 1320.7,957.5 1324.9,955.8 L1334.5,951.7 C1338.6,949.9 1343.4,951.8 1345.1,956 L1349.2,965.6 C1351,969.7 1349.1,974.5 1344.9,976.2 L1335.3,980.3 C1331.2,982 1326.4,980.1 1324.7,976 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1227.4,1176.2 L1231.1,1166.4 C1232.7,1162.2 1237.4,1160.1 1241.6,1161.7 L1251.4,1165.4 C1255.6,1167 1257.7,1171.7 1256.1,1175.9 L1252.4,1185.7 C1250.8,1189.9 1246.1,1192 1241.9,1190.4 L1232.1,1186.7 C1227.9,1185.1 1225.8,1180.4 1227.4,1176.2 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1350.9,1177.6 L1370.2,1169.8 C1371.7,1169.2 1373.4,1169.9 1374,1171.4 L1381.8,1190.7 C1382.4,1192.2 1381.7,1193.9 1380.2,1194.5 L1360.9,1202.3 C1359.4,1202.9 1357.7,1202.2 1357.1,1200.7 L1349.3,1181.4 C1348.7,1179.9 1349.4,1178.2 1350.9,1177.6 Z" stroke="#121E48" stroke-width="3" fill="none"></path>
              <path d="M1226.4,962.9 L1233.4,955.1 C1236.4,951.8 1241.5,951.5 1244.8,954.4 L1252.6,961.4 C1255.9,964.4 1256.2,969.5 1253.3,972.8 L1246.3,980.6 C1243.3,983.9 1238.2,984.2 1234.9,981.3 L1227.1,974.3 C1223.8,971.4 1223.5,966.3 1226.4,962.9 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1238.9,913.8 L1239.9,909 C1240,908.6 1240.4,908.3 1240.8,908.4 L1245.6,909.4 C1246,909.5 1246.3,909.9 1246.2,910.3 L1245.2,915.1 C1245.1,915.5 1244.7,915.8 1244.3,915.7 L1239.5,914.7 C1239.1,914.6 1238.9,914.2 1238.9,913.8 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1271.9,1040.7 L1275.5,1037.3 C1275.8,1037 1276.3,1037 1276.6,1037.3 L1280,1040.9 C1280.3,1041.2 1280.3,1041.7 1280,1042 L1276.4,1045.4 C1276.1,1045.7 1275.6,1045.7 1275.3,1045.4 L1271.9,1041.8 C1271.6,1041.5 1271.6,1041 1271.9,1040.7 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1164.7,1006.1 L1162.8,1001.6 C1162.6,1001.2 1162.8,1000.7 1163.2,1000.5 L1167.7,998.6 C1168.1,998.4 1168.6,998.6 1168.8,999 L1170.7,1003.5 C1170.9,1003.9 1170.7,1004.4 1170.3,1004.6 L1165.8,1006.5 C1165.3,1006.7 1164.9,1006.5 1164.7,1006.1 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1295.1,1169.7 L1296.9,1165.1 C1297.1,1164.7 1297.5,1164.5 1297.9,1164.6 L1302.5,1166.4 C1302.9,1166.6 1303.1,1167 1303,1167.4 L1301.2,1172 C1301,1172.4 1300.6,1172.6 1300.2,1172.5 L1295.6,1170.7 C1295.1,1170.5 1294.9,1170.1 1295.1,1169.7 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1330.4,1120.1 L1335,1118.2 C1335.4,1118 1335.9,1118.2 1336,1118.6 L1337.9,1123.2 C1338.1,1123.6 1337.9,1124.1 1337.5,1124.2 L1332.9,1126.1 C1332.5,1126.3 1332,1126.1 1331.9,1125.7 L1330,1121.1 C1329.8,1120.7 1330,1120.3 1330.4,1120.1 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1391.6,999.7 L1394.9,996 C1395.2,995.7 1395.7,995.6 1396,995.9 L1399.7,999.2 C1400,999.5 1400.1,1000 1399.8,1000.3 L1396.5,1004 C1396.2,1004.3 1395.7,1004.4 1395.4,1004.1 L1391.7,1000.8 C1391.4,1000.6 1391.3,1000.1 1391.6,999.7 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1235.6,1107.6 L1231.7,1100.2 C1231,1099 1231.5,1097.5 1232.7,1096.8 L1240.1,1092.9 C1241.3,1092.2 1242.8,1092.7 1243.5,1093.9 L1247.4,1101.3 C1248.1,1102.5 1247.6,1104 1246.4,1104.7 L1239,1108.6 C1237.8,1109.3 1236.3,1108.8 1235.6,1107.6 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1172,1130.1 L1168.4,1122.5 C1167.8,1121.2 1168.3,1119.8 1169.6,1119.2 L1177.2,1115.6 C1178.5,1115 1179.9,1115.5 1180.5,1116.8 L1184.1,1124.4 C1184.7,1125.7 1184.2,1127.1 1182.9,1127.7 L1175.3,1131.3 C1174.1,1131.9 1172.6,1131.3 1172,1130.1 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1194.6,1035.6 L1202.9,1036.2 C1204.3,1036.3 1205.3,1037.5 1205.2,1038.9 L1204.6,1047.2 C1204.5,1048.6 1203.3,1049.6 1201.9,1049.5 L1193.6,1048.9 C1192.2,1048.8 1191.2,1047.6 1191.3,1046.2 L1191.9,1037.9 C1192,1036.5 1193.2,1035.5 1194.6,1035.6 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1171.1,907 L1168.5,899.1 C1168.1,897.8 1168.8,896.4 1170.1,895.9 L1178,893.3 C1179.3,892.9 1180.7,893.6 1181.2,894.9 L1183.8,902.8 C1184.2,904.1 1183.5,905.5 1182.2,906 L1174.3,908.6 C1172.9,909 1171.5,908.3 1171.1,907 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1332.2,1035.8 L1338.8,1032.5 C1340.3,1031.8 1342.1,1032.4 1342.8,1033.8 L1346.1,1040.4 C1346.8,1041.9 1346.2,1043.7 1344.8,1044.4 L1338.2,1047.7 C1336.7,1048.4 1334.9,1047.8 1334.2,1046.4 L1330.9,1039.8 C1330.2,1038.4 1330.8,1036.6 1332.2,1035.8 Z" stroke="#121E48" stroke-width="1" fill="none"></path>
              <path d="M1400.9,1147.5 L1399.2,1139.3 C1398.9,1137.9 1399.8,1136.6 1401.1,1136.3 L1409.3,1134.6 C1410.7,1134.3 1412,1135.2 1412.3,1136.5 L1414,1144.7 C1414.3,1146.1 1413.4,1147.4 1412.1,1147.7 L1403.9,1149.4 C1402.5,1149.7 1401.1,1148.8 1400.9,1147.5 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1287.5,884.1 L1292.6,870.9 C1293.8,867.7 1297.5,866.1 1300.7,867.3 L1313.9,872.4 C1317.1,873.6 1318.7,877.3 1317.5,880.5 L1312.4,893.7 C1311.2,896.9 1307.5,898.5 1304.3,897.3 L1291.1,892.2 C1287.9,891 1286.2,887.4 1287.5,884.1 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M1464.9,967.8 L1462.3,959.9 C1461.9,958.6 1462.6,957.2 1463.9,956.7 L1471.8,954.1 C1473.1,953.7 1474.5,954.4 1475,955.7 L1477.6,963.6 C1478,964.9 1477.3,966.3 1476,966.8 L1468.1,969.4 C1466.7,969.8 1465.3,969.1 1464.9,967.8 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
              <path d="M1087.9,851.3 L1091.6,841.5 C1093.2,837.3 1097.9,835.2 1102.1,836.8 L1111.9,840.5 C1116.1,842.1 1118.2,846.8 1116.6,851 L1112.9,860.8 C1111.3,865 1106.6,867.1 1102.4,865.5 L1092.6,861.8 C1088.4,860.1 1086.3,855.4 1087.9,851.3 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1211.5,852.7 L1230.8,844.9 C1232.3,844.3 1234,845 1234.6,846.5 L1242.4,865.8 C1243,867.3 1242.3,869 1240.8,869.6 L1221.5,877.4 C1220,878 1218.3,877.3 1217.7,875.8 L1209.9,856.5 C1209.3,855 1210,853.3 1211.5,852.7 Z" stroke="#121E48" stroke-width="3" fill="none"></path>
              <path d="M1155.6,844.7 L1157.4,840.1 C1157.6,839.7 1158,839.5 1158.4,839.6 L1163,841.4 C1163.4,841.6 1163.6,842 1163.5,842.4 L1161.7,847 C1161.5,847.4 1161.1,847.6 1160.7,847.5 L1156.1,845.7 C1155.7,845.6 1155.5,845.1 1155.6,844.7 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1191,795.2 L1195.6,793.3 C1196,793.1 1196.5,793.3 1196.6,793.7 L1198.5,798.3 C1198.7,798.7 1198.5,799.2 1198.1,799.3 L1193.5,801.2 C1193.1,801.4 1192.6,801.2 1192.5,800.8 L1190.6,796.2 C1190.4,795.8 1190.6,795.3 1191,795.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1032.5,805.2 L1028.9,797.6 C1028.3,796.3 1028.8,794.9 1030.1,794.3 L1037.7,790.7 C1039,790.1 1040.4,790.6 1041,791.9 L1044.6,799.5 C1045.2,800.8 1044.7,802.2 1043.4,802.8 L1035.8,806.4 C1034.6,806.9 1033.1,806.4 1032.5,805.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1261.4,822.5 L1259.7,814.3 C1259.4,812.9 1260.3,811.6 1261.6,811.3 L1269.8,809.6 C1271.2,809.3 1272.5,810.2 1272.8,811.5 L1274.5,819.7 C1274.8,821.1 1273.9,822.4 1272.6,822.7 L1264.4,824.4 C1263,824.8 1261.7,823.9 1261.4,822.5 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1254,1268.7 L1256.1,1258.4 C1257,1254 1261.3,1251.2 1265.7,1252.1 L1276,1254.2 C1280.4,1255.1 1283.2,1259.4 1282.3,1263.8 L1280.2,1274.1 C1279.3,1278.5 1275,1281.3 1270.6,1280.4 L1260.3,1278.3 C1255.9,1277.3 1253,1273 1254,1268.7 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1160.5,1286.3 L1156.4,1276.7 C1154.6,1272.6 1156.5,1267.8 1160.7,1266.1 L1170.3,1262 C1174.4,1260.2 1179.2,1262.1 1180.9,1266.3 L1185,1275.9 C1186.8,1280 1184.9,1284.8 1180.7,1286.5 L1171.1,1290.6 C1167,1292.3 1162.2,1290.4 1160.5,1286.3 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1062.2,1273.2 L1069.2,1265.4 C1072.2,1262.1 1077.3,1261.8 1080.6,1264.7 L1088.4,1271.7 C1091.7,1274.7 1092,1279.8 1089.1,1283.1 L1082.1,1290.9 C1079.1,1294.2 1074,1294.5 1070.7,1291.6 L1062.9,1284.6 C1059.5,1281.7 1059.3,1276.6 1062.2,1273.2 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1074.7,1224.1 L1075.7,1219.3 C1075.8,1218.9 1076.2,1218.6 1076.6,1218.7 L1081.4,1219.7 C1081.8,1219.8 1082.1,1220.2 1082,1220.6 L1081,1225.4 C1080.9,1225.8 1080.5,1226.1 1080.1,1226 L1075.3,1225 C1074.9,1224.9 1074.6,1224.5 1074.7,1224.1 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1006.9,1217.3 L1004.3,1209.4 C1003.9,1208.1 1004.6,1206.7 1005.9,1206.2 L1013.8,1203.6 C1015.1,1203.2 1016.5,1203.9 1017,1205.2 L1019.6,1213.1 C1020,1214.4 1019.3,1215.8 1018,1216.3 L1010.1,1218.9 C1008.7,1219.3 1007.3,1218.6 1006.9,1217.3 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1123.3,1194.4 L1128.4,1181.2 C1129.6,1178 1133.3,1176.4 1136.5,1177.6 L1149.7,1182.7 C1152.9,1183.9 1154.5,1187.6 1153.3,1190.8 L1148.2,1204 C1147,1207.2 1143.3,1208.8 1140.1,1207.6 L1126.9,1202.5 C1123.7,1201.3 1122,1197.7 1123.3,1194.4 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M1300.7,1278 L1298.1,1270.1 C1297.7,1268.8 1298.4,1267.4 1299.7,1266.9 L1307.6,1264.3 C1308.9,1263.9 1310.3,1264.6 1310.8,1265.9 L1313.4,1273.8 C1313.8,1275.1 1313.1,1276.5 1311.8,1277 L1303.9,1279.6 C1302.5,1280.1 1301.1,1279.4 1300.7,1278 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
              <path d="M1114.5,943.7 L1116.6,933.4 C1117.5,929 1121.8,926.2 1126.2,927.1 L1136.5,929.2 C1140.9,930.1 1143.7,934.4 1142.8,938.8 L1140.7,949.1 C1139.8,953.5 1135.5,956.3 1131.1,955.4 L1120.8,953.3 C1116.4,952.4 1113.6,948.1 1114.5,943.7 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1094,1030 L1101.7,1022.9 C1105,1019.9 1110.1,1020.1 1113.1,1023.3 L1120.2,1031 C1123.2,1034.3 1123,1039.4 1119.8,1042.4 L1112.1,1049.5 C1108.8,1052.5 1103.7,1052.3 1100.7,1049.1 L1093.6,1041.4 C1090.6,1038.2 1090.8,1033 1094,1030 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1021,961.3 L1016.9,951.7 C1015.1,947.6 1017,942.8 1021.2,941.1 L1030.8,937 C1034.9,935.2 1039.7,937.1 1041.4,941.3 L1045.5,950.9 C1047.3,955 1045.4,959.8 1041.2,961.5 L1031.6,965.6 C1027.5,967.4 1022.8,965.5 1021,961.3 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1047.3,1163 L1066.6,1155.2 C1068.1,1154.6 1069.8,1155.3 1070.4,1156.8 L1078.2,1176.1 C1078.8,1177.6 1078.1,1179.3 1076.6,1179.9 L1057.3,1187.7 C1055.8,1188.3 1054.1,1187.6 1053.5,1186.1 L1045.7,1166.8 C1045.1,1165.3 1045.8,1163.6 1047.3,1163 Z" stroke="#121E48" stroke-width="3" fill="none"></path>
              <path d="M991.4,1155 L993.2,1150.4 C993.4,1150 993.8,1149.8 994.2,1149.9 L998.8,1151.7 C999.2,1151.9 999.4,1152.3 999.3,1152.7 L997.5,1157.3 C997.3,1157.7 996.9,1157.9 996.5,1157.8 L991.9,1156 C991.5,1155.9 991.3,1155.4 991.4,1155 Z" stroke="none" fill="#121E48" fill-rule="nonzero"></path>
              <path d="M1026.8,1105.5 L1031.4,1103.6 C1031.8,1103.4 1032.3,1103.6 1032.4,1104 L1034.3,1108.6 C1034.5,1109 1034.3,1109.5 1033.9,1109.6 L1029.3,1111.5 C1028.9,1111.7 1028.4,1111.5 1028.3,1111.1 L1026.4,1106.5 C1026.2,1106.1 1026.4,1105.6 1026.8,1105.5 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1088,985.1 L1091.3,981.4 C1091.6,981.1 1092.1,981 1092.4,981.3 L1096.1,984.6 C1096.4,984.9 1096.5,985.4 1096.2,985.7 L1092.9,989.4 C1092.6,989.7 1092.1,989.8 1091.8,989.5 L1088.1,986.2 C1087.7,985.9 1087.7,985.4 1088,985.1 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M1028.6,1021.2 L1035.2,1017.9 C1036.7,1017.2 1038.5,1017.8 1039.2,1019.2 L1042.5,1025.8 C1043.2,1027.3 1042.6,1029.1 1041.2,1029.8 L1034.6,1033.1 C1033.1,1033.8 1031.3,1033.2 1030.6,1031.8 L1027.3,1025.2 C1026.5,1023.7 1027.1,1021.9 1028.6,1021.2 Z" stroke="#121E48" stroke-width="1" fill="none"></path>
              <path d="M1097.2,1132.8 L1095.5,1124.6 C1095.2,1123.2 1096.1,1121.9 1097.4,1121.6 L1105.6,1119.9 C1107,1119.6 1108.3,1120.5 1108.6,1121.8 L1110.3,1130 C1110.6,1131.4 1109.7,1132.7 1108.4,1133 L1100.2,1134.7 C1098.8,1135 1097.5,1134.2 1097.2,1132.8 Z" stroke="none" fill="#FFFFFF" fill-rule="nonzero"></path>
              <path d="M983.8,869.5 L988.9,856.3 C990.1,853.1 993.8,851.5 997,852.7 L1010.2,857.8 C1013.4,859 1015,862.7 1013.8,865.9 L1008.7,879.1 C1007.5,882.3 1003.8,883.9 1000.6,882.7 L987.4,877.6 C984.2,876.3 982.6,872.7 983.8,869.5 Z" stroke="#FFFFFF" stroke-width="3" fill="none"></path>
              <path d="M1161.2,953.1 L1158.6,945.2 C1158.2,943.9 1158.9,942.5 1160.2,942 L1168.1,939.4 C1169.4,939 1170.8,939.7 1171.3,941 L1173.9,948.9 C1174.3,950.2 1173.6,951.6 1172.3,952.1 L1164.4,954.7 C1163.1,955.1 1161.6,954.4 1161.2,953.1 Z" stroke="#FFFFFF" stroke-width="2" fill="none"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { mapGetters } from 'vuex'
import * as types from '@store/types'

export default {
  computed: mapGetters({ colorA: types.SETTINGS_COLOR_A, colorB: types.SETTINGS_COLOR_B })
}
</script>
